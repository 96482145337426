
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Yrsa:ital,wght@0,300..700;1,300..700&display=swap');
:root {
    /*colors*/
    --main-color: #ac2018;
    --main-bg-color: #f0f0f0;
    --main-bg-secondary-color: #b8b8b8;
    --main-text-color: #1e1e1e;
    /*font sizes*/
    --title-font-size: 2.5rem;
    --subtitle-font-size: 1.7rem;
    --text-font-size: 1rem;
    /*font family*/
    --subtitle-font-family: "Montserrat", sans-serif;
    --title-font-family:  "Montserrat", sans-serif;
    --text-font-family: "Montserrat", sans-serif;
    /*other*/
    --fancy-border-radius: 0 50px 0 50px;
    --swiper-theme-color:var(--main-bg-color) !important;
}
html{
    width: 100%;
    min-height: 100%;
}
body {
    width: 100vw;
    min-height: 100%;
    box-sizing: border-box;
    margin: 0;
    background-color: var(--main-bg-color);
    font-size: 16px;
    font-size: var(--text-font-size);
    font-family: var(--text-font-family);
    justify-content: center;
    outline: none;
}
main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    font-family: var(--subtitle-font-family);
    font-size: 40px;
    font-size: var(--title-font-size);
    color: var(--main-color);
    margin: 0;
}
.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}
.section > div {
    max-width: 1280px;
    width: 80%;
}
.section > h2 {
    width: 80%;
    max-width: 1280px;
}
.section > p {
    width: 80%;
    max-width: 1280px;
}
.row-section {
    display: flex;
    flex-direction: row;
    gap: 96px;
    gap: 6rem;
}
.row-section > div {
    width: 50%;
}
.column-section {
    display: flex;
    flex-direction: column;
}
.container-90 {
    width: 90%;
}
.container-50 {
    width: 50%;
}
.container-50 img{
    width: 100%;
}
.container-60 {
    width: 60%;
}
.container-30 {
    width: 30%;
}
.dark-section {
    background-color: var(--main-text-color);
    color: var(--main-bg-color);
}
.red-section {
    background-color: var(--main-color);
    color: var(--main-bg-color);
}
.red-section h2{
    color: var(--main-bg-color);
}
.question-h2 {
    color: var(--main-text-color);
}
.question-h2 > span {
    color: var(--main-color);
}.wave-container {  
    height: 40vh;
    background-color: #f0f0f0;
    position: relative;
  }
  
  .wave-container::before {   
    content: "";
    width: 100%;
    height: 53px;
    position: absolute;
    bottom: -0.3%;
    left: 0;
    background-size: auto;
    background-repeat: repeat no-repeat;
    background-position: 20vw bottom;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  92' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 67L50 63C100 59 200 51 300 34C400 18 500 -7 600 1C700 9 800 51 900 67C1000 84 1100 76 1150 72L1200 67V92H1150C1100 92 1000 92 900 92C800 92 700 92 600 92C500 92 400 92 300 92C200 92 100 92 50 92H0V67Z' fill='%23ac2018'/></svg>");
  }
  .wave-container + div {
    background-color: var(--main-color);
    color: var(--main-bg-color);
  }
  .wave-container + div > h2 {
    color: var(--main-bg-color);
  }
/*header*/
.header {
    z-index: 2;
    left: 0;
    position: fixed;
    background-color: rgba(240, 240, 240, .8);
    width: 100%;
    height: 82px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.header h1 {
    text-align: center;
}
.header img {
    margin-left: 30px;
    height: 70px;
}
.header div {
    width: 95px;
    height: 1px;
}
.header-main-page {
    z-index: 2;
    position: fixed;
    width: 100%;
}
.header-main-page-section {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
}
.img-header {
    max-width: 700px;
}
.header-main-page-section > div {
    width: 90%;
    gap: 64px;
    gap: 4rem;
    justify-content: center;
    align-items: center;
}
.header-text {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 32px 0;
    padding: 2rem 0;
}
.header-main-page-section h1 {
    font-size: 56px;
    font-size: 3.5rem;
    margin: 0 0 8px 0;
    margin: 0 0 .5rem 0;
}
.header-main-page-section p {
    font-size: 24px;
    font-size: 1.5rem;
    margin: 0;
}
.scrolled-nav {
    background-color: var(--main-bg-color);
}
.top-header-img {
    position: absolute;
    width: 30%;
    top: 0;
    right: 0;
}
.bttm-header-img {
    width: 100vw;
    min-height: 100px;
}
.logo-main-page {
    position: fixed;
    height: 50px;
    top: 16px;
    left: 30px;
    z-index: 1000;
}
/*menu*/
.menu-space {
    margin-top: 80px;
}
nav {
    z-index: 900;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 120vh;
    background: var(--main-color);
    color: var(--main-bg-color);
    z-index: 200;
    clip-path: circle(25px at calc(100% - 45px) 45px);
    transition: all 300ms ease-in-out;
}
nav.active {
    clip-path: circle(75%);
    background: var(--main-text-color);
}
nav .ham-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}
nav .ham-btn span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 2px;
    background: var(--main-bg-color);
    transition: all 200ms ease-in-out;
}
nav .ham-btn span:nth-child(1) {
    top: 30%;
}
nav .ham-btn span:nth-child(2) {
    top: 50%;
}
nav .ham-btn span:nth-child(3) {
    top: 70%;
}
nav.active .ham-btn span:nth-child(1) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}
nav.active .ham-btn span:nth-child(2) {
    display: none;
}
nav.active .ham-btn span:nth-child(3) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}
.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
}
.display-nav {
    margin-top: -20vh;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px;
    padding: 1rem;
}
nav .links-nav {
    list-style: none;
    width: 50%;
}
nav .links-nav a {
    font-family: var(--title-font-family);
    text-wrap: nowrap;
    position: relative;
    display: inline-block;
    margin-top: 40px;
    text-decoration: none;
    font-size: 40px;
    font-size: var(--title-font-size);
    font-weight: bold;
    line-height: 40px;
    line-height: 2.5rem;
    color: var(--main-bg-secondary-color);
    letter-spacing: 5px;
}
nav .links-nav a:before {
  content:"";
  position:absolute;
  color: var(--main-bg-color);
  top:0;
  left:0;
  width:0%;
  overflow:hidden;
  transition:all 0.5s;
}
nav .links-nav .link-nav:nth-child(1) a:before {
  content:"O nas";
}
nav .links-nav .link-nav:nth-child(2) a:before {
  content:"Serwis";
}
nav .links-nav .link-nav:nth-child(3) a:before {
  content:"Skup - Sprzedaż";
}
nav .links-nav .link-nav:nth-child(4) a:before {
  content:"Akcesoria";
}
nav .links-nav .link-nav:nth-child(5) a:before {
  content:"Kontakt";
}
nav .links-nav .link-nav:nth-child(6) a:before {
  content:"Regulamin";
}
nav .links-nav a:hover:before {
  width:100%;
}
.info-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 64px;
    gap: 4rem;
}
.worktime-nav {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 48px;
    gap: 3rem;
    font-weight: 100;
}
/*fixed info*/
.fixed-info {
    z-index: 900;
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.fixed-info-item {
    background-color: var(--main-color);
    color: var(--main-bg-color);
    padding: 12px;
    border-radius: 50%;
    font-size: 16px;
    font-size: var(--text-font-size);
}
.fixed-info-item:first-child {
    margin-bottom: 10px;
}
/*footer*/
.footer-section {
    background-color: var(--main-text-color);
    color: var(--main-bg-color);
    padding-bottom: 24px;
    padding-bottom: 1.5rem;
}
.footer-contact{
    padding-top: 32px;
    padding-top: 2rem;
}
.footer-contact p{
    margin: 0;
}
.info-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 4em;
}
.info-footer a {
    text-decoration: none;
    color: var(--main-bg-color);
}
.info-footer h2 {
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
}
.info-footer .row-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    gap: 2rem;
}
.info-footer .row-section .column-section:first-child{
    width: 60%;
}
.info-footer .row-section .column-section:nth-child(2){
    width: 40%;
}
.column-section .contact-item{
    display: flex;
    flex-direction: row;
    gap: 32px;
    gap: 2rem;
    margin: 8px 0;
    margin: .5rem 0;
}
.contact-icon {
    width: 50px;
    font-weight: bold;
}
.info-worktime {
    margin: 16px 0;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    gap: 1rem;
}
.info-worktime > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 64px;
    gap: 4rem;
}
.info-worktime > div > div:first-child {
    font-weight: bold;
}
.copyrights {
    margin-top: 80px;
    margin-top: 5rem;
    width: 100%;
    text-align: left;
    max-width: 1280px;
}
.copyrights a {
    color: var(--main-bg-color);
}
/*o nas*/
.with-u-section {
    display: flex;
    gap: 80px;
    gap: 5rem;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.with-u-section:nth-child(2) {
    flex-direction: row-reverse;
    margin-top: 48px;
    margin-top: 3rem;
}
.with-u-text {
    width: 50%;
}
.with-u-section > img {
    width: 47%;
    max-height: 300px;
    object-fit: cover;
    border-radius: var(--fancy-border-radius);
    filter: brightness(0.9) contrast(1.3) saturate(1.1);
}
.scroll-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 64px;
    gap: 4rem;
}
.scroll-section > h2 {
    width: 50%;
    position: sticky;
    top: 100px;
}
.scroll-item-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    gap:4rem;
}
.title-scroll-item {
    color: var(--main-text-color);
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
}
.scroll-item-container > div{
    width: 70%;
}
/*opinie klientów*/
.testimonials-swiper
{
    width: 100%;
    overflow: hidden;
    margin-top: 32px;
    margin-top: 2rem;
    transition: transform 0.5s ease-in-out;
}
.testimonial-item {
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    padding: 50px;
    border-radius: 0 50px 0 50px;
    max-width: 800px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.star-container {
    display: flex;
    flex-direction: row;
    gap: 4.8px;
    gap: .3rem;
    color: #f5c542;
}
.testimonial-profile-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.testimonial-profile-container > div {
    background-color: #AC2018;
    color: var(--main-bg-color);
    font-weight: bold;
    font-size: 23px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.testimonial-profile-container > h3 {
    font-weight: normal;
}
.swiper {
    padding-bottom: 32px !important;
    padding-bottom: 2rem !important;
}
@media (max-width: 768px) 
{
    .swiper-wrapper
    {
        margin: 0 10px 40px;
    }
    .swiper-button
    {
        display: none;
    }
}
/*serwis*/
.red-list-container {
    background-color: var(--main-color);
    color: var(--main-bg-color);
    gap: 32px;
    gap: 2rem;
}
.red-list-container > h2 {
    color: var(--main-bg-color);
    width: 80%;
}
.item-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 32px 64px;
    padding: 2rem 4rem;
    align-items: center;
    width: 80%;
    background-color: var(--main-bg-color);
    border-radius: var(--fancy-border-radius);
}

.item-list > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    gap: 2rem;
}
.number-list {
    width: 38.4px;
    width: 2.4rem;
    font-weight: bold;
    font-size: 40px;
    font-size: var(--title-font-size);
    margin: 0;
}
.title-list {
    width: 100%;
    color: var(--main-text-color);
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
    margin: 0;
}
.text-list {
    margin: 0;
    width: 100%;
    color: var(--main-text-color);
}
.our-service > h2 {
    margin: 32px 0;
    margin: 2rem 0;
}
.our-service > .column-section > .row-section > div {
    margin: 32px 0;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.our-service > .column-section > .row-section:nth-child(2) {
    flex-direction: row-reverse;
}
.our-service > .column-section > .row-section > div > h2 {
    color: var(--main-text-color);
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
}
.our-service img{
    max-height: 300px;
    object-fit:cover;
    border-radius: var(--fancy-border-radius);
}
/*akcesoria*/
.accessories-container > div {
    margin: 96px 0;
    margin: 6rem 0;
}
.item-accessories-title {
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
    color: var(--main-color);
}
/*regulamin*/
.regulamin-section h2 {
    font-size: 27.2px;
    font-size: var(--subtitle-font-size);
}
/*kontakt*/
.contact-section {
    display: flex;
    flex-direction: column;
    gap: 64px;
    gap: 4rem;
}
.contact-section h2 {
    font-size: 40px;
    font-size: var(--title-font-size);
    padding-bottom: 32px;
    padding-bottom: 2rem;
}
.contact-section a {
    text-decoration: none;
    color: var(--main-text-color);
}
/*form*/
form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    gap: 2rem;
    margin-bottom: 96px;
    margin-bottom: 6rem;
}
form .form-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    gap: 2rem;
}
form span {
    color: var(--main-color);
    font-weight: bold;
}
form .form-row .input-data{
    width: 100%;
    height: 40px;
    position: relative;
}
form .form-row .textarea{
    height: 70px;
}
.form-checkbox {
   accent-color: var(--main-color);
   transform: scale(1.5);
   margin-right: 10px;
}
.input-data input, .textarea textarea{
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    font-size: 17px;
  }
.input-data input {
    border-bottom: 2px solid rgba(0,0,0, 0.12);
}
  .input-data input, .textarea textarea{
    background-color: var(--main-bg-color);
  }
  .input-data input:focus, .textarea textarea{
    outline-width: 0;
  }
  .input-data input:focus ~ .required_input, .input-data input:valid ~ .required_input {
    transform: translateY(-20px);
    font-size: 14px;
    color: var(--main-color);
  }
    .optional_input{
        transform: translateY(-20px);
        font-size: 14px;
        color: var(--main-color);
    }
  .input-data textarea:focus ~ label, .input-data textarea:valid ~ label {
    color: var(--main-color);
  }
  .textarea {
    margin-top: 40px;
  }
  .textarea textarea{
    border: 2px solid rgba(0,0,0, 0.12);
    resize: none;
    padding-top: 0px;
  }
  .input-data label{
    position: absolute;
    pointer-events: none;
    bottom: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
  }
  .textarea label{
    width: 100%;
    bottom: 80px;
  }
  
button.submit-btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: 22.4px;
    font-size: 1.4rem;
    width: 160px;
    width: 10rem;
    height: auto;
  }
  
  button.submit-btn .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 48px;
    width: 3rem;
    height: 48px;
    height: 3rem;
    background: var(--main-color);
    border-radius: 26px;
    border-radius: 1.625rem;
  }
  
  button.submit-btn .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--main-bg-color);
  }
  
  button.submit-btn .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 10px;
    left: 0.625rem;
    width: 18px;
    width: 1.125rem;
    height: 2px;
    height: 0.125rem;
    background: none;
  }
  
  button.submit-btn .circle .icon.arrow::before {
    position: absolute;
    content: '';
    top: -4px;
    top: -0.25rem;
    right: 1px;
    right: 0.0625rem;
    width: 1px;
    width: 0.625rem;
    height: 1px;
    height: 0.625rem;
    border-top: 2px solid var(--main-bg-color);
    border-top: 0.125rem solid var(--main-bg-color);
    border-right: 2px solid var(--main-bg-color);
    border-right: 0.125rem solid var(--main-bg-color);
    transform: rotate(45deg);
  }
  
  button.submit-btn .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6.4px 0;
    padding: 0.4rem 0;
    margin: 0 0 0 29.6px;
    margin: 0 0 0 1.85rem;
    color: var(--main-color);
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
  }
  
  button.submit-btn:hover .circle {
    width: 100%;
  }
  
  button.submit-btn:hover .circle .icon.arrow {
    background-color: var(--main-bg-color);
    transform: translate(16px, 0);
    transform: translate(1rem, 0);
  }
  
  button.submit-btn:hover .button-text {
    color: var(--main-bg-color);
  }
  
  @media (max-width: 700px) {
    .container .text{
      font-size: 30px;
    }
    .container form{
      padding: 10px 0 0 0;
    }
    .container form .form-row{
      display: block;
    }
  }
/**/
.google-map {
    width: 100%; 
    height: auto; 
    border:0; 
}
.regulamin-section > h2 {
    margin: 48px 0 32px 0;
    margin: 3rem 0 2rem 0;
}
.regulamin-section > ul {
    width: 80%;;
    max-width: 1280px;
}
@media (max-width: 820px) {
    .row-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
    .with-u-section:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
    .row-section > img {
        width: 100%;
    }
    .row-section > div, .row-section > h2{
        width: 100%;
    }
    .with-u-section:nth-child(2) > img,
    .with-u-section:nth-child(2) > div,
    .with-u-section:nth-child(2) > h2 {
        width: 100%;
    }
    .scroll-section > h2{
        position: static;
        padding-bottom: 64px;
        padding-bottom: 4rem;
    }
    .scroll-item-container {
        align-items: flex-start;
    }
    .our-service > .column-section > .row-section:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .our-service > .column-section > .row-section:nth-child(2) {
        width: 100%;
    }
    .info-nav{
        display: none;
    }
    .links-nav{
        width: 100%;
    }
    /*footer adjustment*/
    .info-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
    .info-footer > div{
        width: 100%;
    }
    .info-footer .row-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
    }
    .info-footer .row-section .column-section:first-child, .info-footer .row-section .column-section {
        width: 100%; 
    }
}
@media (max-width: 1000px) {
    /*footer adjustment*/
    .info-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        gap: 2rem;
    }
    .info-footer > div{
        width: 100%;
    }
    .bottom-space {
        margin-bottom: 32px;
        margin-bottom: 2rem;
    }
    .info-footer > .row-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
    }
}
@media (max-width: 1250px) {
    .header-main-page-section > div {
        flex-direction: column;
        gap: 16px;
        gap: 1rem;
    }
    .header-main-page-section > div > div {
        width: 100%;
    }
    .header-main-page-section > div > div h1{
        font-size: 48px;
        font-size: 3rem;
    }

}

@media(max-width:850px) {
    .wave-container::before {    
      height: 26.5px;
    }  
    .worktime {
        width: 100% !important;
    }
  }
@media (max-width: 800px) {
    .header-main-page-section > div > div h1{
        font-size: 40px;
        font-size: 2.5rem;
    }
}
@media (max-width: 1370px) {
    .info-footer {
        flex-direction: column;
        align-items: flex-start;
    }
    .info-footer > div:first-child{
        width: 100%;
    }
    .info-footer > div:nth-child(2){
        width: 50%;
    }
}
@media (max-width: 400px) {
    .info-worktime > div{
        gap: 16px;
        gap: 1rem;
    }
    nav .links-nav a{
        font-size: 27.2px;
        font-size: var(--subtitle-font-size);
        margin: 7px 0;
    }
}
@media (max-width: 430px) {
    .header h1 {
        font-size: 24px;
        font-size: 1.5rem;
    }
}
@media (max-width: 360px) {
    .info-worktime > div{
        flex-direction: column;
    }
}
@media (max-width: 550px) {
    .info-contact .column-section:first-child .contact-item {
        gap: 3.2px;
        gap: .2rem;
    }
    .info-contact .column-section:first-child .contact-item .contact-icon{
        width: 32px;
        width: 2rem;
    }
}
@media (max-width: 550px) {
    nav .links-nav a {
        font-size: 27.2px;
        font-size: var(--subtitle-font-size);
        margin-top: 20px;
    }
}
@media (max-width: 410px) {
    nav .links-nav a {
        font-size: 16px;
        font-size: 1rem;
        margin-top: 10px;
    }
}
@media (max-width: 459px) {
    .header-main-page-section > div > div h1 {
        font-size: 32px;
        font-size: 2rem;
    }
    .header-main-page-section p {
        font-size: 19.2px;
        font-size: 1.2rem;
    }
}
@media (max-width: 360px) {
    .header-main-page-section > div > div h1 {
        font-size: 24px;
        font-size: 1.5rem;
    }
}
@media (max-width: 275px) {
    .header-main-page-section > div > div h1 {
        font-size: 20.8px;
        font-size: 1.3rem;
    }
}


.formOutcome {
    color: var(--main-bg-color);
    background-color: var(--main-color);
    border-radius: 20px;
    padding: 20px;
    margin-top: 120px;
}